body {
  font-family: "Poppins", sans-serif;
  color: #0c0c0c;
  background-color: #ffffff;
  overflow-x: hidden;
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 75px 0;
}

.layout_padding2-top {
  padding-top: 75px;
}

.layout_padding2-bottom {
  padding-bottom: 75px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

.heading_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #0c0c0c;
  font-weight: 700;
}

.heading_container h2 {
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

.heading_container h2 .dot_design {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #17b978;
  border-radius: 100%;
}

.heading_container.heading_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.heading_container p {
  margin: 0;
  margin-top: 10px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*header section*/
.hero_area {
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.hero_area .hero_bg_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.hero_area .hero_bg_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.hero_area .hero_bg_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(0, 0, 0, 0.45)),
      to(rgba(0, 0, 0, 0.01))
    ),
    -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.01)));
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.45),
      rgba(0, 0, 0, 0.01)
    ),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.01));
}

.sub_page .hero_area {
  min-height: auto;
}

.sub_page .hero_area .hero_bg_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.45)),
    to(rgba(0, 0, 0, 0.65))
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.45),
    rgba(0, 0, 0, 0.65)
  );
}

.header_section {
  padding: 10px 0;
}

.navbar-brand {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
}

.navbar-brand span {
  color: #ffffff;
}

.navbar-brand img {
  width: 80px;
  height: 50px;
  margin-right: 10px;
}

.navbar-brand:hover {
  color: inherit;
}

.custom_nav-container {
  padding: 0;
}

.custom_nav-container .navbar-nav {
  margin-left: auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 10px 25px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-nav .nav-item:hover .nav-link {
  color: #17b978;
}

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: #000000;
  background-color: #ffffff;
}

.custom_nav-container .nav_search-btn:hover {
  color: #17b978;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #ffffff;
  margin: 7px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  border-radius: 5px;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::before,
.custom_nav-container .navbar-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  top: -10px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::after {
  top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before,
.custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 0;
  margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
  display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin: 0;
  margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
  -webkit-transform: none;
  transform: none;
}

/*end header section*/
/* slider section */
.slider_section {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 45px 0 75px 0;
}

.slider_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider_section #customCarousel1 {
  width: 100%;
  position: unset;
}

.slider_section .detail-box {
  color: #ffffff;
}

.slider_section .detail-box h1 {
  font-weight: 600;
  margin-bottom: 15px;
  color: #ffffff;
  text-transform: uppercase;
}

.slider_section .detail-box a {
  display: inline-block;
  padding: 10px 40px;
  background-color: #17b978;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #17b978;
}

.slider_section .detail-box a:hover {
  background-color: transparent;
  color: #17b978;
}

.slider_section .img-box img {
  width: 100%;
}

.slider_section .carousel_btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 110px;
}

.slider_section .carousel_btn-box a {
  top: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #ffffff;
  opacity: 1;
  color: #071a52;
  color: #ffffff;
  font-size: 12px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 5px;
}

.slider_section .carousel_btn-box a:hover {
  background-color: #17b978;
  border-color: #17b978;
}

.slider_section .carousel_btn-box .carousel-control-prev {
  left: 25px;
}

.slider_section .carousel_btn-box .carousel-control-next {
  right: 25px;
}

.service_section {
  position: relative;
  background: white;
}

.service_section .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-top: 45px;
  border-radius: 5px;
}

.service_section .box .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90px;
  min-width: 90px;
  height: 90px;
  border-radius: 100%;
  /* background-color: #071a52; */
  background-color: white;
  position: relative;
  margin-bottom: -45px;
  overflow: hidden;
  border-width: 1px;
  border-color: red;
}

.service_section .box .img-box img {
  width: 100px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.service_section .box .detail-box {
  color: #0c0c0c;
  background-color: #f0f0f0;
  padding: 60px 15px 15px 15px;
  border-radius: 5px;
  height: 280px;
}

.service_section .box .detail-box1 {
  color: #0c0c0c;
  background-color: #f0f0f0;
  padding: 35px 15px 15px 15px;
  border-radius: 5px;
  width: 100%;
}

.service_section .box .detail-box h5 {
  font-weight: bold;
  text-transform: uppercase;
}

.service_section .box .detail-box1 h5 {
  font-weight: bold;
  text-transform: uppercase;
}

.service_section .box .detail-box a {
  color: #15ab6f;
  font-weight: 500;
}

.service_section .box .detail-box a:hover {
  color: #071a52;
}

.service_section .box:hover .img-box img {
  /* -webkit-filter: brightness(0) invert(56%) sepia(16%) saturate(2165%)
    hue-rotate(105deg) brightness(101%) contrast(83%);
  filter: brightness(0) invert(56%) sepia(16%) saturate(2165%)
    hue-rotate(105deg) brightness(101%) contrast(83%); */
}

.service_section .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 45px;
}

.service_section .btn-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #17b978;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #17b978;
}

.service_section .btn-box a:hover {
  background-color: transparent;
  color: #17b978;
}

.about_section .heading_container {
  margin-bottom: 15px;
}

.about_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about_section .img-box img {
  max-width: 100%;
  border-radius: 10px;
}

.about_section .detail-box h3 {
  font-weight: bold;
}

.about_section .detail-box p {
  margin-top: 15px;
}

.about_section .detail-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #17b978;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #17b978;
  margin-top: 15px;
}

.about_section .detail-box a:hover {
  background-color: transparent;
  color: #17b978;
}

/* contact section */
.contact_section {
  background: #071a52;
  color: #ffffff;
}

.contact_section .heading_container {
  margin-bottom: 25px;
}

.contact_section .form_container {
  padding: 75px 0 75px 15px;
}

.contact_section .form_container .heading_container {
  color: #ffffff;
}

.contact_section form .form-control {
  height: 60px;
  border: none;
  background-color: #f6f6f6;
  border-radius: 0;
  margin-bottom: 20px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contact_section form #inputMessage {
  height: 145px;
}

.contact_section .btn-box {
  margin-top: 15px;
}

.contact_section .btn-box .submit_btn {
  display: inline-block;
  padding: 12px 45px;
  background-color: #17b978;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #17b978;
  width: 100%;
}

.contact_section .btn-box .submit_btn:hover {
  background-color: transparent;
  color: #17b978;
}

.contact_section .map_container {
  height: 100%;
}

.contact_section .map_container .map {
  height: 100%;
  min-height: 345px;
}

.contact_section .map_container .map #googleMap {
  height: 100%;
}

/* contact section end */
.client_section .client_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  margin-top: 15px;
  padding: 30px 25px 25px 25px;
  background-color: #071a52;
  color: #ffffff;
  border-radius: 5px;
}

.client_section .client_container .client_id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.client_section .client_container .client_id h5 {
  font-weight: 600;
  margin-bottom: 5px;
}

.client_section .client_container .client_id h6 {
  margin-bottom: 0;
}

.client_section .client_container .img-box {
  width: 95px;
  margin-right: 15px;
}

.client_section .client_container .img-box img {
  width: 100%;
  border-radius: 100%;
}

.client_section .client_container .detail-box {
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ffffff;
}

.client_section .client_container .detail-box p {
  position: relative;
  font-size: 15px;
}

.client_section .client_container .detail-box p span {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-top: 25px;
  color: #17b978;
  font-size: 28px;
}

.client_section .carousel-control-prev,
.client_section .carousel-control-next {
  width: 50px;
  height: 50px;
  border-radius: 0px;
  background-color: #071a52;
  opacity: 1;
  top: 28%;
  color: #ffffff;
  font-size: 12px;
  margin: 0 2.5px;
}

.client_section .carousel-control-prev:hover,
.client_section .carousel-control-next:hover {
  background-color: #17b978;
}

.client_section .carousel-control-prev {
  left: 0;
}

.client_section .carousel-control-next {
  right: 0;
}

.client_section .carousel_btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.news_section .box {
  margin-top: 45px;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
}

.news_section .box .img-box {
  position: relative;
}

.news_section .box .img-box img {
  width: 100%;
}

.news_section .box .detail-box {
  padding: 15px 25px 25px 25px;
}

.news_section .box .detail-box h5 {
  font-weight: bold;
}

.news_section .box .detail-box p {
  font-size: 15px;
}

.news_section .box .detail-box a {
  display: inline-block;
  margin-top: 15px;
  color: #17b978;
}

.news_section .box .news_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -5px;
}

.news_section .box .news_info h6 {
  margin: 0 5px;
}

.news_section .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 45px;
}

.news_section .btn-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #071a52;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #071a52;
}

.news_section .btn-box a:hover {
  background-color: transparent;
  color: #071a52;
}

.info_section {
  background-color: #071a52;
  color: #ffffff;
  padding-top: 45px;
  padding-bottom: 10px;
}

.info_section .row > div {
  margin-bottom: 35px;
}

.info_section .contact_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.info_section .contact_nav a {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.info_section .contact_nav a i {
  font-size: 22px;
  margin-right: 10px;
}

.info_section .contact_nav a:hover {
  color: #17b978;
}

.info_section h4 {
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 600;
}

.info_section .social_box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
}

.info_section .social_box a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 18px;
  margin-right: 10px;
}

.info_section .social_box a:hover {
  color: #17b978;
  border-color: #17b978;
}

.info_section .info_form input {
  width: 100%;
  border: none;
  height: 45px;
  margin-bottom: 15px;
  padding-left: 25px;
  background-color: #eaeaea;
  outline: none;
  color: #101010;
}

.info_section .info_form button {
  display: inline-block;
  padding: 10px 45px;
  background-color: #17b978;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #17b978;
}

.info_section .info_form button:hover {
  background-color: transparent;
  color: #17b978;
}

/* footer section*/
.footer_section {
  position: relative;
  background-color: #ffffff;
  text-align: center;
}

.footer_section p {
  color: #252525;
  padding: 25px 0;
  margin: 0;
}

.footer_section p a {
  color: inherit;
}

.info_detail a {
  display: flex;
}

.info_detail a h4 {
  margin-top: 7px;
}

.info_detail a img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
